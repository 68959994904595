'use strict';

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.every.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.string.iterator.js";
$.extend({
  keys: function keys(obj) {
    return $.map(obj, function (v, k) {
      return k;
    });
  },
  includes: function includes(arr, val) {
    return $.inArray(val, arr);
  }
});
$(document).on('shiny:sessioninitialized', function () {
  var i18nCustom = document.getElementById('i18n-cstm-trns');

  if (!i18nCustom) {
    i18nCustom = {
      language: 'en'
    };
  } else {
    i18nCustom = JSON.parse(i18nCustom.innerText);
  }

  if (i18nCustom.resources) {
    $.keys(i18nCustom.resources).forEach(function (lng) {
      var resource = i18nCustom.resources[lng];

      if (resource.custom) {
        if (resource.translation) {
          $.keys(resource.custom).forEach(function (keyGroup) {
            $.extend(resource.translation[keyGroup], resource.custom[keyGroup]);
          });
        } else {
          resource.translation = resource.custom;
        }
      }
    });
  }

  function localize(selector, opts) {
    selector = selector || '[data-i18n]';
    opts = opts || {};
    var $els = $(selector).filter(function () {
      return $.includes(this.dataset, 'i18n');
    });
    Shiny.setInputValue('__tutorial_language', i18next.language);

    if (!$els.length) {
      return;
    }

    $els.each(function (idx) {
      var optsItem = $.extend({}, opts);

      if (this.dataset.i18nOpts) {
        optsItem = $.extend(optsItem, JSON.parse(this.dataset.i18nOpts));
      }

      if (this.dataset.i18n) {
        this.innerHTML = i18next.t(this.dataset.i18n, optsItem);
      }

      var i18nAttrs = $.keys(this.dataset).filter(function (x) {
        return x.match('i18nAttr');
      });

      for (var j = 0; j < i18nAttrs.length; j++) {
        this.setAttribute(i18nAttrs[j].replace(/^i18nAttr/, '').toLowerCase(), i18next.t(this.dataset[i18nAttrs[j]], optsItem));
      }
    });
    return $els;
  }

  i18next.init({
    lng: i18nCustom.language || 'en',
    fallbackLng: 'en',
    ns: 'translation',
    resources: i18nCustom.resources || {}
  }, function (err, t) {
    if (err) return console.log('[i18next] Error loading translations:', err);
    localize();
  });

  window.tutorial.$localize = function (lang, selector, opts) {
    if (typeof lang === 'undefined') {
      return i18nCustom;
    }

    i18next.changeLanguage(lang);
    localize(selector, opts);
  };

  $(document).on('shiny:value', '.tutorial-question', function (ev) {
    setTimeout(function () {
      localize(ev.target.closest('.tutorial-question').querySelectorAll('[data-i18n]'));
    }, 0);
  });
  $(document).on('shiny:value', '.tutorial-exercise-output', function (ev) {
    setTimeout(function () {
      localize(ev.target.closest('.tutorial-exercise-output').querySelectorAll('[data-i18n]'));
    }, 0);
  });
  $(document).on('i18n', function (ev) {
    localize(ev.target);
    localize(ev.target.querySelectorAll('[data-i18n]'));
  });

  function localizeHandler(x) {
    var selector, language;

    if (typeof x === 'string' || Array.isArray(x) && x.every(function (s) {
      return typeof s === 'string';
    })) {
      selector = x;
    } else if (_typeof(x) === 'object') {
      selector = x.selector || '[data-i18n]';
      language = x.language;
    } else {
      return console.log('localize message must be a string with selector(s) or an object with optional keys selector and language.');
    }

    if (language) {
      i18next.changeLanguage(language);
    }

    localize(selector, x.opts || {});
  }

  Shiny.addCustomMessageHandler('localize', localizeHandler);
});